$(document).ready(function() {
  $('.pa-list-header').on('click', function(e){
    that = $(this).find('.show-answers')
    old_this = $(this)
      
    if($(this).parents('.pa-list-container').find('.pa-list-items').length == 0){
      $.ajax({
        url: that.attr("href"),
        method: 'GET',
        format: 'js',
        success: function(data) {
          $(old_this).parents('.pa-list-container').find('.pa-list-items').removeClass('pa-list-items-hidden')
          $(old_this).find('.arrow').removeClass('arrow-down');
          $(old_this).find('.arrow').addClass('arrow-side');
        }
      });
    } else {
      $(this).parents('.pa-list-container').find('.pa-list-items').toggleClass('pa-list-items-hidden')
      $(this).find('.arrow').toggleClass('arrow-down');
      $(this).find('.arrow').toggleClass('arrow-side');
    }
  });

  $('.program-list-header').on('click', function(e){
    $(this).next('.program-list-items').toggleClass('program-list-items-hidden');
    $(this).find('.arrow').toggleClass('arrow-down');
    $(this).find('.arrow').toggleClass('arrow-side');
  });

  $('.user-show').on('click', function(e){
    var href = $(e.target).parents('tr').data('href');
    window.location = href;
  }); 
  $('.chat-show').on('click', function(e){
    var href = $(e.target).parents('tr').data('href');
    window.location = href;
  }); 
});
