require("@rails/ujs").start()
// require("turbolinks").start()
require("jquery")
require("@rails/activestorage").start()
require("channels")
require("packs/pie-chart")
require("packs/doughnut-chart")
require("packs/programs_assigned")
require("packs/created_programs")
require("packs/active_users")
require("packs/user_improvements")
require("packs/average_interactions")
require("packs/average_time")
require("packs/steps_activity")
require("packs/people_referred")
require("packs/individuals_admin")
require("packs/new_users")
require("packs/symptom_improvement")

import 'bootstrap';

var ready = function(){

  // Access Code filters
  $('body').on('click', '#choose-all-access-codes-filter', function(){
    var accessCodes = $('#filter_form_access_code');

    accessCodes.find('option').prop('selected', true);

    return false;
  });

  $('body').on('click', '#reset-all-access-codes-filter', function(){
    var accessCodes = $('#filter_form_access_code');

    accessCodes.find('option').prop('selected', false);

    return false;
  });





  $('body').on('change', '.corporate-filters #filter_form_snapshot_id', function(){
    $(this).parents('form').submit()
  })


  $('body').on('change', '.corporate-filters #filter_form_server', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_access_code', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_organization_id', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_division_id', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_department_id', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_location_id', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_time_range', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_gender', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.corporate-filters #filter_form_age', function(){
    $(this).parents('form').submit()
  })

  $('body').on('change', '.dashboard-component #region', function(){
    var form = $("#new_filter_form");
    $("#filter_form_region").val($(this).val());
    form.submit()
  });
}

$(document).on('page:load', ready);
$(document).ready(ready)
