$(document).ready(function() {
  $('.info-people-referred').tooltip();
  $(".people_referred_chart").each(function(){
    render_chart($(this)[0]);
  });
});

function round_scale(x) {
  return Math.ceil(x / 50) * 50;
}

function render_chart(element){

  var ctx = element

  var data = $(".people_referred_data").data("chart");

  if(data.labels.length > 15) {
    fontSize = 0.01;
  } else {
    fontSize = 10;
  }

  var yMax;

  var d = data.datasets.map(function(dataset){ return dataset.data }).flat();

  yMax = Math.max.apply(Math, d) * 2;

  if(yMax == 0) { yMax = 100;}


  new Chart(ctx, {
    type: 'bar',
    data: data,

    options: {
      // tooltips: {
      //   enabled: false
      // },
      plugins: {
        labels: {
          fontColor: '#000',
          fontSize: fontSize,
          render: 'value'
        }
      },
      scales: {
          yAxes: [{
              ticks: {
                  min: 0,
                  max: round_scale(yMax),
                  stepSize: (round_scale(yMax))/5
              }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
      },
      legend: {
        display: false
      }
    }
  });
}