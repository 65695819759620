$(document).ready(function() {

  $(".doughnutChart").each(function(){
    doughnut_chart($(this)[0]);
  });
  
});

function doughnut_chart(element){

  var ctx = element;

  var data = {
    datasets: [{
      data: [62, 35, 3],
           backgroundColor: [
      '#458a3f', 
      '#295ba0', 
      '#289399',
    ],
    borderColor: [
      '#458a3f', 
      '#295ba0', 
      '#289399',
    ],
    borderWidth: 1

    }],
      
    labels: [
      'Assess problem',
      'Stay healthy',
      'Book Physio'
    ]
  };

  var myDoughnutChart = new Chart(ctx, {
    type: 'doughnut',
    data: data,
    options: {
      tooltips: {
        enabled: false 
      },
      plugins: {
        labels: {
          fontColor: '#fff',
          fontSize: 10
        }
      },
      legend: {
        display: false
      }
    }
  });
}



