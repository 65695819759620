$(document).ready(function() {
  $('.info-user-engagement').tooltip();
  $(".average_interactions_chart").each(function(){
    average_interactions_chart($(this)[0]);
  });
});

function round_scale(x) {
  return Math.ceil(x / 100) * 100;
}

function average_interactions_chart(element){

  var ctx = element

  var data = $(".average_interactions_data").data("chart");
  var fontSize;

  if(data.labels.length > 15) {
    fontSize = 0.01;
  } else {
    fontSize = 10;
  }
  var yMax;

  yMax = Math.max.apply(Math, data.datasets[0].data) * 1.5;

  if(yMax == 0) { yMax = 300}


  var my_average_interactions_chart = new Chart(ctx, {
    type: 'bar',
    data: data,

    options: {
      // tooltips: {
      //   enabled: false
      // },
      plugins: {
        labels: {
          fontColor: '#000',
          fontSize: fontSize,
          render: 'value'
        }
      },
      scales: {
          yAxes: [{
              ticks: {
                  min: 0,
                  max: round_scale(yMax),
                  stepSize: (round_scale(yMax))/5
              }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
      },
      legend: {
        display: false
      }
    }
  });
}