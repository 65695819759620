$(document).ready(function() {
  $('.info-user-improvements').tooltip();
  $(".user_improvements_chart").each(function(){
    user_improvements_chart($(this)[0]);
  });
  
});

function user_improvements_chart(element){

  var ctx = element;

  var legend = $(".user_improvements_data").data("chart").data;
  var bgColors     = legend.map(colorFunction);
  var borderColors = bgColors;
  var amount       = legend.map(amountFunction);

  function colorFunction(element) {
    return element.color;
  }

  function amountFunction(element) {
    return element.amount;
  }
  
  var data = {
    datasets: [{
      data: amount,
      backgroundColor: bgColors,
      borderColor: borderColors,
      borderWidth: 1
    }]
  };

  var my_user_improvements_chart = new Chart(ctx, {
    type: 'doughnut',
    data: data,
    options: {
      tooltips: {
        enabled: false 
      },
      plugins: {
        labels: {
          fontColor: 'fff',
          fontSize: 0.01
        }
      },
      legend: {
        display: false
      }
    }
  });
}



