$(document).ready(function() {
  $('.info-created-programs').tooltip();
  $(".created_programs_chart").each(function(){
    created_programs_chart($(this)[0]);
  });
  
});

function created_programs_chart(element){

  var ctx          = element;
  var legend       = $(".created_programs_data").data("chart").data;
  var bgColors     = legend.map(colorFunction);
  var borderColors = bgColors;
  var amount       = legend.map(amountFunction);

  function colorFunction(element) {
    return element.color;
  }
  function amountFunction(element) {
    return element.amount;
  }

  var data = {
    datasets: [{
      data: amount,
      backgroundColor: bgColors,
      borderColor: borderColors,
      borderWidth: 1

    }]
  };

  var mycreatedprogramschart = new Chart(ctx, {
    type: 'doughnut',
    data: data,
    options: {
      tooltips: {
        enabled: false 
      },
      plugins: {
        labels: {
          fontColor: '#fff',
          fontSize: 0.01
          // render: 'value'
        }
      },
      legend: {
        display: false
      }
    }
  });
}



