$(document).ready(function() {
  $('.info-active-users').tooltip();
  $(".active_users_chart").each(function(){
    active_users_chart($(this)[0]);
  });
});

function round_scale(x) {
  return Math.ceil(x / 100) * 100;
}

function active_users_chart(element){

  var ctx = element

  var data = $(".active_users_data").data("chart");

  var fontSize;

  if(data.labels.length > 15) {
    // safari requires 0.01
    fontSize = 0.01;
  } else {
    fontSize = 10;
  }
  var yMax;
  var d = data.datasets.map(function(dataset){ return dataset.data }).flat();

  yMax = Math.max.apply(Math, d) * 1.5;

  if(yMax == 0) { yMax = 300}

  var my_active_users_chart = new Chart(ctx, {
    type: 'bar',
    data: data,

    options: {
      // tooltips: {
      //   enabled: false
      // },
      plugins: {
        labels: {
          fontColor: '#000',
          fontSize: fontSize,
          render: 'value'
        }
      },
      scales: {
          yAxes: [{
              ticks: {
                  min: 0,
                  max: round_scale(yMax),
                  stepSize: (round_scale(yMax))/5
              }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
      },
      legend: {
        display: false
      }
    }
  });
}