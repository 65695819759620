$(document).ready(function() {
   $(".pieChart").each(function(){
    pie_chart($(this)[0]);
  });
  
});

function pie_chart(element){

  var ctx = element

  var data = {
    datasets: [{
      data: [62, 35, 3],
           backgroundColor: [
      'rgba(255, 99, 132, 1)',
      '#00a9ff',
      'rgba(255, 206, 86, 1)',
      // 'rgba(75, 192, 192, 1)',
      // 'rgba(153, 102, 255, 1)',
      // 'rgba(255, 159, 64, 1)'
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      '#00a9ff',
      'rgba(255, 206, 86, 1)'
      // 'rgba(75, 192, 192, 1)',
      // 'rgba(153, 102, 255, 1)',
      // 'rgba(255, 159, 64, 1)'
    ],
    borderWidth: 1

    }],
      
    labels: [
      'Assess problem',
      'Stay healthy',
      'Book Physio'
    ]
  };

  var myPieChart = new Chart(ctx, {
    type: 'pie',
    data: data,
    options: {
      tooltips: {
        enabled: false 
      },
      plugins: {
        labels: {
          fontColor: '#fff',
          fontSize: 10
        }
      },
      legend: {
        display: false
      }
    }
  });
}



