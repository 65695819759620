$(document).ready(function() {
  $('.info-programs-assigned').tooltip();
  $(".programs_assigned_chart").each(function(){
    programs_assigned_chart($(this)[0]);
  });
});

function round_scale(x) {
  if(x%5 == 0) { 
    return (Math.ceil(x / 5) * 5) + 5;
  } else {
    return (Math.ceil(x / 5) * 5) 
  }
}

function programs_assigned_chart(element){

  var ctx = element

  var data = $(".programs_assigned_data").data("chart");
  var fontSize;

  if(data.labels.length > 15) {
    fontSize = 0.01;
  } else {
    fontSize = 10;
  }
  var yMax;

  var d = data.datasets.map(function(dataset){ return dataset.data }).flat();

  yMax = Math.max.apply(Math, d) * 1;
  
  if(yMax == 0) { yMax = 100;}

  var my_programs_assigned_chart = new Chart(ctx, {
    type: 'bar',
    data: data,
    options: {
      // tooltips: {
      //   enabled: false
      // },
      plugins: {
        labels: {
          fontColor: '#000',
          fontSize: fontSize,
          render: 'value'
        }
      },
      scales: {
          yAxes: [{
              ticks: {
                  min: 0,
                  max: round_scale(yMax),
                  stepSize: (round_scale(yMax))/5
              }
          }],
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
      },
      legend: {
        display: false
      }
    }
  });
}



